import { useState } from "react";

export const TermsOfService = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        //padding: "100px",
        top: "0px",
        left: "0px",
        minHeight: "200vh",

        paddingLeft: "10vw",
        paddingRight: "10vw",
        paddingTop: "60px",
        paddingBottom: "30px",
        //backgroundColor: "black",
        margin: "0px",
        color: "white",
        textAlign: "left",
      }}
    >
      <h1>TERMS OF SERVICE AGREEMENT</h1>
      <p>
        Welcome to TempoTiles. These Terms of Service govern your access to and
        use of our website, products, and services. By accessing or using our
        website, you agree to be bound by these Terms of Service.
      </p>
      <h2>Use of Website</h2>
      You may not use our website for any illegal or unauthorized purpose. You
      must not use our website to harass, abuse, or harm others, or to transmit
      any content that is offensive or objectionable.
      <h2>Products and Services</h2>
      All products and services are subject to availability and we reserve the
      right to limit quantities or discontinue products or services at any time.
      We make every effort to ensure that the descriptions and images of our
      products and services are accurate, but we cannot guarantee that they are
      error-free. This includes the QR codes which may not be operational in
      some instances.
      <h2>Payment and Ordering</h2>
      You may place an order for our products and services through our website.
      All orders are subject to acceptance by us, and we reserve the right to
      refuse any order for any reason. Payment must be made at the time the
      order is placed, and all payments are processed securely through our
      payment processor, Stripe.
      <h2>Shipping and Delivery</h2>
      We offer shipping to customers in the United States. We strive to ship all
      orders within 2 business days of receiving the order, but we cannot
      guarantee a specific delivery date. Delivery times may vary based on your
      location and other factors beyond our control.
      <h2>No Refunds</h2>
      All sales are final and we do not offer refunds for any reason. We
      encourage you to review your order carefully before submitting it.
      <h2>Disclaimer of Warranties</h2>
      Our website and all products and services provided through our website are
      provided on an "as is" and "as available" basis. We make no
      representations or warranties of any kind, express or implied, including
      without limitation, warranties of merchantability, fitness for a
      particular purpose, or non-infringement.
      <h2>Limitation of Liability</h2>
      In no event shall our ecommerce business be liable for any direct,
      indirect, incidental, special, or consequential damages arising out of or
      in connection with the use or inability to use our website or any products
      or services provided through our website.
      <h2>Changes to Terms of Service</h2>
      We reserve the right to modify these Terms of Service at any time without
      notice. By continuing to access or use our website after any changes are
      made, you agree to be bound by the revised Terms of Service.
      <h2>Contact Us</h2>
      <p>
        If you have any questions or concerns about these Terms of Service,
        please contact us at Tempotiles@tempotiles.com
      </p>
      <p>
        By using our website and our products and services, you agree to these
        Terms of Service. If you do not agree to these Terms of Service, you may
        not use our website or our products and services.
      </p>
    </div>
  );
};
