import "./App.css";
import React, { useState, useEffect, useRef, Component } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";

import SiteHeader from "./landingPage/SiteHeader.js";

/*import { SpotifyApiContext } from "react-spotify-api";
import Cookies from "js-cookie";*/

import { SpotifyAuth, Scopes } from "react-spotify-auth";
import "react-spotify-auth/dist/index.css";

import CollageGenerator from "./collageGenerator/CollageGenerator";
import WelcomeScreen from "./collageGenerator/WelcomeScreen";
import { TermsOfService } from "./order/TermsOfService";

import tiles from "./tiles.png";

import axios from "axios";
import LandingPage from "./landingPage/LandingPage";
import { ClickCounter } from "./order/ClickCounter";

function App() {
  const reload = () => window.location.reload();

  return (
    <Router>
      <Routes>
        <Route
          path="/callback"
          element={
            <CollageScreen collageSource="artists" areControlsVisible={false} />
          }
        ></Route>
        <Route path="/" element={<LandingPage />}></Route>
        <Route
          path="/builder/:playlistID"
          element={
            <CollageScreen
              collageSource="playlist"
              initialToken=""
              areControlsVisible={true}
            />
          }
        ></Route>
        <Route
          path="/builder"
          element={
            <WelcomeScreen
              //collageSource="playlist"
              //initialToken=""
              areControlsVisible={false}
            />
          }
        ></Route>
        <Route
          path="/success/"
          element={
            <CollageScreen
              collageSource="playlist"
              initialToken=""
              success={true}
              areControlsVisible={false}
            />
          }
        ></Route>
        <Route path="/terms" element={<TermsOfService />}></Route>
        <Route path="/clicks" element={<ClickCounter />}></Route>

        <Route
          path="/.well-known/apple-developer-merchantid-domain-association"
          onEnter={reload}
        />

        <Route path="/landingpage" element={<LandingPage />} />
        <Route
          path="*"
          element={
            <CollageScreen
              collageSource="playlist"
              initialToken=""
              areControlsVisible={true}
            />
          }
        ></Route>
      </Routes>
    </Router>
  );
}

const CollageScreen = (props) => {
  const { collageSource, success, areControlsVisible } = props;

  return (
    <React.Fragment>
      <CollageGenerator
        //authToken={getAuth()}
        currentUserName="NEED THIS"
        startingCollageSource={collageSource}
        success={success}
        areControlsVisible={areControlsVisible}
      />
    </React.Fragment>
  );
};

export default App;
