import { initializeApp } from "firebase/app";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import {
  getFirestore,
  collection,
  addDoc,
  setDoc,
  getDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyAMZqoLforrYvfIviwp9usgy4jFpKM0oZE",
  authDomain: "tempotiles.firebaseapp.com",
  projectId: "tempotiles",
  storageBucket: "tempotiles.appspot.com",
  messagingSenderId: "51658618665",
  appId: "1:51658618665:web:4dc69dcac61690bf222938",
  measurementId: "G-LBF3Q3KK2X",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Initialize Cloud Storage and get a reference to the service
const storage = getStorage(app);

export const addOrderDocument = (orderInfo) => {
  //Create a document in the Orders collection
  //add the details of an associated upload image
  const collectionRef = collection(db, "Orders");

  return addDoc(collectionRef, orderInfo);
};

export const updateOrderDocument = (docRef, data) => {
  return setDoc(docRef, data, { merge: true });
};

export const deleteOrder = (docRef) => {
  getDoc(docRef).then((snapshot) => {
    //console.log(snapshot.data());
    const storageRef = ref(storage, `files/${snapshot.data().storageID}`);

    deleteObject(storageRef)
      .then(() =>
        //console.log("successfully deleted file")
        {}
      )
      .catch((error) => {}); //console.error("error deleting file: " + error));
    deleteDoc(docRef)
      .then(() =>
        //console.log("successfully deleted document")
        {}
      )
      .catch((error) => {}); //console.error("error deleting document: " + error));
  });
};

/*export const uploadToStorage = (dataURL, fileName) => {
  // Create a root reference
  const storageRef = ref(storage, `files/${fileName}`);
  return uploadBytes(storageRef, dataURL, "data_url");
};*/

export const uploadToStorage = (blob, fileName) => {
  // Create a root reference
  const storageRef = ref(storage, `files/${fileName}`);
  return uploadBytes(storageRef, blob, "data_url");
};

export const addClick = async (buttonName) => {
  const docRef = doc(db, "Clicks", buttonName);
  const docSnap = await getDoc(docRef);
  console.log(docSnap.data());
  setDoc(docRef, { clicks: docSnap.data()["clicks"] + 1 }, { merge: true });
};

export const getClicks = async () => {
  const docRef = doc(db, "Clicks", "Let's go Button");
  const docSnap = await getDoc(docRef);
  return docSnap.data()["clicks"];
};

export default uploadToStorage;
