import "../App.css";
import "../MenusAndButtons.css";
import React, { useState, useEffect, useRef, Component } from "react";
import ReactDOMServer from "react-dom/server";

import { SpotifyAuth, Scopes } from "react-spotify-auth";
import "react-spotify-auth/dist/index.css";

import whiteZoomIcon from "../icons/whiteZoomIcon.png";
import zoomIcon from "../icons/zoomIcon.png";

import whiteShuffleIcon from "../icons/whiteShuffleIcon.png";
import whiteMenuIcon from "../icons/whiteMenuIcon.png";
import qrCodeIcon from "../icons/qrCodeIcon.png";
import logo from "../logo.png";
import whiteHelpIcon from "../icons/whiteHelpIcon.png";
import whiteEditIcon from "../icons/whiteEditIcon.png";

import whiteCloseButton from "../icons/whiteCloseButton.png";

import Collage from "./Collage";
import Menu from "./Menu";
import Order from "../order/Order";
import { PlaylistSwitcher } from "./PlaylistSwitcher";

import { getNewCollageData } from "../SpotifyAPI";

import axios from "axios";
import { Buffer } from "buffer";
import qs from "qs";
import QRCode from "./QRCode";
import WelcomeScreen from "./WelcomeScreen";
import { useParams } from "react-router";
import { map } from "@firebase/util";

const client_id = process.env.REACT_APP_SPOTIFY_API_ID; // Your client id
const client_secret = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET; // Your secret
const auth_token = Buffer.from(
  `${client_id}:${client_secret}`,
  "utf-8"
).toString("base64");

const getAuth = () => {
  try {
    //make post request to SPOTIFY API for access token, sending relavent info
    const token_url = "https://accounts.spotify.com/api/token";
    const data = qs.stringify({ grant_type: "client_credentials" });

    return axios.post(token_url, data, {
      headers: {
        Authorization: `Basic ${auth_token}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    //console.log(response.data.access_token);
  } catch (error) {
    //on fail, log the error in console
    console.log(error);
  }
};

const twentyempties = () => {
  let twenty = [];
  let counter = 20;
  while (counter > 0) {
    twenty.push({
      id: counter,
      imageURLOptions: ["", "", ""],
      textOptions: [
        "Add some more songs to the playlist",
        "Add some more songs to the playlist",
        "Add some more songs to the playlist",
      ],
      qrCodeURLOptions: ["spotify.com", "spotify.com", "spotify.com"],
      colorConfig: 0,
    });
    counter = counter - 1;
  }
  return twenty;
};

const CollageGenerator = (props) => {
  const { playlistID } = useParams();
  const {
    authToken,
    /*currentUserName,*/ startingCollageSource,
    success,
    areControlsVisible,
  } = props;
  const [token, setToken] = useState("");
  const [userAuthorized, setUserAuthorized] = useState(false);
  const [playlistName, setPlaylistName] = useState("");
  const [playlistURL, setPlaylistURL] = useState(
    "https://open.spotify.com/playlist/5ji5gjsdhivhLYF5WOee0R"
  );

  //The source of the collage data
  const [collageSource, setCollageSource] = useState("playlist");
  const [toShowIndex, setToShowIndex] = useState(0);
  //TO-DO: Replace To show index with choice of Text
  const [whichImage, setWhichImage] = useState(0); //Album Cover or Artist
  const [whichText, setWhichText] = useState(0); //Also changes QR URL. Track, Album Name, Artist
  const [timeRange, setTimeRange] = useState("medium_term");
  const [customPlaylistID, setCustomPlaylistID] = useState(playlistID);

  //The collage data for this user
  const [playlistData, setPlaylistData] = useState(twentyempties());

  //collage aesthetics
  const [showQR, setShowQR] = useState(true);
  const [collageBackgroundColor, setCollageBackgroundColor] = useState("black");
  //TO-DO: add an array to hold the order of the collagePieces

  //States of the Menus
  const [menuVisible, setMenuVisible] = useState(false);
  const [controlsVisible, setControlsVisible] = useState(areControlsVisible);
  const [helpVisible, setHelpVisible] = useState(true);
  const [showInstructions, setShowInstructions] = useState(false);
  const [editingCollage, setEditingCollage] = useState(false);
  const [selectedPiece, setSelectedPiece] = useState(-1);
  const [ordering, setOrdering] = useState(false);

  const [successfulOrder, setSuccessfulOrder] = useState(false);

  const [collageRef, setCollageRef] = useState(null);
  const [imageSize, setImageSize] = useState("8vh");

  const shufflePieces = (pieces) => {
    var i = 0;
    var indices = [];
    while (i < pieces.length) {
      indices.push(i);
      i = i + 1;
    }

    indices.sort((a, b) => 0.5 - Math.random());

    var newPieces = [];
    i = 0;
    while (i < pieces.length) {
      newPieces.push(pieces[indices[i]]);
      i = i + 1;
    }

    setPlaylistData(newPieces);
  };

  const swapOutPiece = (index) => {
    let newPieces = [...playlistData];

    if (playlistData.length > 20) {
      let substitutedPiece = newPieces[index];
      newPieces[index] = newPieces[20];
      newPieces.splice(20, 1);
      newPieces.push(substitutedPiece);
    }
    setPlaylistData(newPieces);
  };

  const swapPieces = (index) => {
    let newPieces = [...playlistData];
    let pieceToSwap = newPieces[index];
    if (selectedPiece > -1) {
      newPieces[index] = newPieces[selectedPiece];
      newPieces[selectedPiece] = pieceToSwap;
    }
    setPlaylistData(newPieces);
    setSelectedPiece(-1);
  };

  const changePieceColor = (index) => {
    let newPieces = [...playlistData];
    let pieceToChange = [...playlistData][index];
    if (pieceToChange.colorConfig >= 5) {
      pieceToChange.colorConfig = 0;
    } else {
      pieceToChange.colorConfig = pieceToChange.colorConfig + 1;
    }

    newPieces[index] = pieceToChange;
    setPlaylistData(newPieces);
  };

  useEffect(() => {
    if (token) {
      if (!userAuthorized) {
        console.log("Re-Authorizing user");
        setUserAuthorized(true);
        //setCollageSource("artists");
        //maybe not the best idea to do this here if the user gets a new token (token refresh)
      }
    }
  }, [token]);

  const toggleZoom = () => {
    if (imageSize === "15vh") {
      setImageSize("8vh");
      return;
    }
    setImageSize("15vh");
  };

  const getCollageRef = (collageRef) => {
    setCollageRef(collageRef);
  };

  //TO-DO: T
  useEffect(() => {
    //console.log(success);
    success ? setSuccessfulOrder(true) : setSuccessfulOrder(false);
  }, []);

  //TO-DO: Make it clear that the user needs to log in again
  const handleError = (error) => {
    console.log("timed out");
    window.alert(
      error
      //"Your session expired. Log in again to continue."
    );

    setUserAuthorized(false);
    setToken("");
  };

  useEffect(() => {
    //console.log(customPlaylistID);
    let queryParameters = [];
    if (collageSource == "playlist") {
      getAuth().then((response) => {
        var playlistIDToUse = customPlaylistID;
        if (!customPlaylistID || customPlaylistID.length !== 22) {
          playlistIDToUse = "5ji5gjsdhivhLYF5WOee0R";
        }

        const access_token = response.data.access_token;
        queryParameters.push(playlistIDToUse);

        //queryParameters.push("limit=50");
        getNewCollageData(
          access_token,
          collageSource,
          queryParameters,
          (pieces, playlistName) => {
            setPlaylistData(pieces);
            setPlaylistName(playlistName);
            setPlaylistURL(
              "https://open.spotify.com/playlist/" + playlistIDToUse
            );
          },
          handleError
        );
      });
    } else {
      if (userAuthorized) {
        setToShowIndex(0);
        queryParameters = ["limit=20", "time_range=" + timeRange];
        getNewCollageData(
          token,
          collageSource,
          queryParameters,
          setPlaylistData,
          handleError
        );

        //TO-DO: show some loading until this completes
      }
    }
  }, [collageSource, timeRange, userAuthorized, customPlaylistID]);

  return (
    <div style={{}}>
      <Collage
        pieces={playlistData}
        backgroundColor={collageBackgroundColor}
        setBackgroundColor={setCollageBackgroundColor}
        imageSize={imageSize}
        toShowIndex={toShowIndex}
        setCollageRef={getCollageRef}
        showQR={showQR}
        playlistName={playlistName}
        playlistURL={playlistURL}
        isEditing={editingCollage}
        swapOutPiece={swapOutPiece}
        selectedPiece={selectedPiece}
        setSelectedPiece={setSelectedPiece}
        swapPieces={swapPieces}
        changePieceColor={changePieceColor}
        showInstructions={showInstructions}
        setShowInstructions={setShowInstructions}
      ></Collage>

      {ordering ? (
        <Order
          collageRef={collageRef}
          imageSize={imageSize}
          stopOrdering={() => {
            //alert("cancelled order");
            setOrdering(false);
            //setMenuVisible(true);
          }}
        />
      ) : (
        <div>
          {controlsVisible ? (
            <div>
              <PlaylistSwitcher
                setPlaylistID={setCustomPlaylistID}
                closeMenu={() => setControlsVisible(false)}
                playlistName={playlistName}
              />

              <button
                className="startOrderButton"
                //disabled={true}
                onClick={() => {
                  setOrdering(true);
                  setMenuVisible(false);
                  setEditingCollage(false);
                  setSelectedPiece(-1);
                }}
              >
                Print your poster
              </button>

              <div className="zoomShuffleContainer">
                <button className="zoomButton" onClick={() => toggleZoom()}>
                  <img src={zoomIcon}></img>
                </button>{" "}
                {/*<button
                  className="shuffleButton"
                  onClick={() => shufflePieces(playlistData)}
                >
                  <img src={whiteShuffleIcon}></img>
              </button>{" "}*/}
                <button
                  className="shuffleButton"
                  style={{
                    backgroundColor: editingCollage ? "#999999" : "#333333",
                  }}
                  onClick={() => {
                    setEditingCollage(!editingCollage);
                    setShowInstructions(true);
                    setImageSize("15vh");
                    setSelectedPiece(-1);
                  }}
                >
                  <img src={whiteEditIcon}></img>
                </button>{" "}
                <button
                  className="qrCodeButton"
                  onClick={() => setShowQR(!showQR)}
                  style={{
                    backgroundColor: showQR ? "#999999" : "#333333",
                  }}
                >
                  <img src={qrCodeIcon}></img>
                </button>{" "}
              </div>
            </div>
          ) : (
            //()
            //{true ?
            <div>
              {
                successfulOrder ? (
                  <div>
                    <div
                      style={{
                        position: "fixed",
                        backgroundColor: "#111111",
                        top: "0px",
                        left: "0px",
                        minHeight: "150vh",
                        minWidth: "150vw",
                        opacity: "80%",
                        //zIndex: "5",
                      }}
                    />
                    <div className="successPopUp">
                      <h1>Success!</h1>
                      <img src={logo} style={{ width: "60%" }}></img>

                      <button
                        style={{
                          color: "white",
                          backgroundColor: "transparent",
                          border: "none",
                          padding: "20px",
                        }}
                        onClick={() => {
                          setControlsVisible(true);
                        }}
                      >
                        close
                      </button>
                    </div>
                  </div>
                ) : null
                /*<WelcomeScreen
                  helpVisible={helpVisible}
                  setHelpVisible={setHelpVisible}
                  setCustomPlaylistID={setCustomPlaylistID}
                  closeMenu={() => setControlsVisible(true)}
                ></WelcomeScreen>*/
              }
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CollageGenerator;
