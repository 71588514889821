import { useState, useEffect } from "react";

import { getClicks } from "../DatabaseOperations";

export const ClickCounter = (props) => {
  const [clicks, setClicks] = useState("");

  useEffect(() => {
    getClicks().then((currentClicks) => {
      setClicks(currentClicks);
    });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          //padding: "100px",
          top: "0px",
          left: "0px",
          //minHeight: "200vh",
          height: "80vh",

          //paddingLeft: "10vw",
          //paddingRight: "10vw",
          //paddingTop: "60px",
          //paddingBottom: "30px",
          //backgroundColor: "black",
          margin: "0px",
          color: "white",
          //textAlign: "left",
          //alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1>The "Let's go" button has been clicked</h1>
        <caption
          style={{
            fontSize: "100px",
          }}
        >
          {clicks}
        </caption>
        <h2>times</h2>
      </div>
    </div>
  );
};
