import { useState, useEffect } from "react";
import "../App.css";

export const PlaylistSwitcher = (props) => {
  const { setPlaylistID, closeMenu, playlistName } = props;

  const [playlistURL, setPlaylistURL] = useState("");
  const [errorText, setErrorText] = useState("");
  const [editing, setEditing] = useState(false);

  /*useEffect(() => {
    if (playlistURL.length > 10) {
      handleSubmitPlaylist(null);
    }
  }, [playlistURL]);*/

  const handleSubmitPlaylist = (event) => {
    if (event) {
      event.preventDefault();
    }
    //setPlaylistID(""); //22 characters long
    if (validatePlaylistURL()) {
      console.log(playlistURL);
    } else {
      console.log("bad input");
      console.log(playlistURL);
    }
  };

  const validatePlaylistURL = () => {
    var playlistID = "";

    //Parse the URL to find the ID

    //https://open.spotify.com/playlist/2AE9BV9ZM5WHTN1ExQEmfa?si=Hmj56cxtQw-mmt_cgfgeeg
    var removeBaseURL = playlistURL.split("spotify.com/playlist/");
    //https://open. -and- 5UnGFvKZ3NbEedhn3nCfAJ?si=2a89f85a5a4b4140
    //remove share ID
    if (removeBaseURL[1]) {
      var idArray = removeBaseURL[1].split("?");
      playlistID = idArray[0];
      console.log(playlistID);
    } else {
      setErrorText("Please enter a valid link to a Spotify playlist");
      return false;
    }

    //sanity check the resulting ID
    if (playlistID.length === 22) {
      //TO-DO: Check if the playlist can be accessed

      //setErrorText("good input");
      //setPlaylistID(playlistID);
      window.location.href = "/builder/" + playlistID;
      return true;
    } else {
      setErrorText("Please enter a valid link to a Spotify playlist");
      return false;
    }
  };

  const inputChangeHandler = (event) => {
    setPlaylistURL(event.target.value);
  };

  /*const pasteHandler = (event) => {
    setPlaylistURL(event.target.value);
    handleSubmitPlaylist(event);
  };*/

  return (
    <div>
      {!editing ? (
        <div className="nowShowing">
          <div>Music from "{playlistName}"</div>
          <button
            onClick={() => {
              //setControlsVisible(!controlsVisible);
              //setHelpVisible(false);
              setEditing(!editing);
            }}
          >
            change
          </button>{" "}
        </div>
      ) : (
        <div
          className="nowShowing"
          style={{
            //width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            //width: "40%",
            //height: "150px",
            color: "white",
            //fontSize: "min(3.5vw, 16px)",

            //width: "80%",
            paddingTop: "10px",
          }}
        >
          <form onSubmit={handleSubmitPlaylist}>
            <label>
              {/*Playlist URL:*/}
              <input
                style={{
                  width: "60%",
                  height: "12px",
                  //marginBottom: "0px",
                  backgroundColor: "black",
                  color: "white",
                  borderRadius: "8px",
                  fontSize: "16px", //so apple doesnt auto zoom in
                  borderColor: "white",
                  borderStyle: "solid",
                  padding: "5px",
                }}
                type="text"
                inputMode="none"
                //name="playlist"
                //value="5UnGFvKZ3NbEedhn3nCfAJ"
                onChange={(e) => inputChangeHandler(e)}
                value={playlistURL}
                //onPaste={(e) => pasteHandler(e)}
              />
            </label>
            <input
              style={{
                width: "25%",
                height: "28px",
                //marginBottom: "10px",
                backgroundColor: "green",
                color: "white",
                borderRadius: "14px",
                borderWidth: "0px",
                borderColor: "green",
                padding: "5px",
                marginLeft: "5px",
              }}
              type="submit"
              value="Go"
            />
          </form>
          <div
            style={{
              marginTop: "5px",
              fontSize: "12px",
              color: "red",
              //paddingBottom: "5px",
            }}
          >
            {errorText}
          </div>
        </div>
      )}
    </div>
  );
};
