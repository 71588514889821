import axios from "axios";

const baseURLs = {
  artists: "https://api.spotify.com/v1/me/top/artists",
  tracks: "https://api.spotify.com/v1/me/top/tracks",
  playlist: "https://api.spotify.com/v1/playlists/",
};

const checkPlaylistID = () => {};

const formRequestURL = (source, queryParameters) => {
  let requestURL = baseURLs[source];
  if (source === "playlist") {
    return requestURL + queryParameters[0];
  }
  if (queryParameters.length > 0) {
    requestURL = requestURL + "?";
    for (let i = 0; i < queryParameters.length; i++) {
      if (i !== 0) {
        requestURL = requestURL + "&";
      }
      requestURL = requestURL + queryParameters[i];
    }
  }
  return requestURL;
};

const getNewCollageData = (
  authToken,
  source, //artists, tracks, playlist
  queryParameters,
  completionHandler,
  errorHandler
) => {
  const headers = {
    Authorization: "Bearer " + authToken,
    "Content-Type": "application/json",
  };

  //parameters will be object in form
  // baseURL: String
  // queryParameters: [String]

  return axios
    .get(formRequestURL(source, queryParameters), {
      headers,
    })
    .then((response) =>
      createArrayFromResponse(authToken, source, response, completionHandler)
    )
    .catch((error) => {
      return errorHandler(error);
    });
};

const createArrayFromResponse = (
  authToken,
  source,
  response,
  completionHandler
) => {
  //console.log("getting " + source);

  let newCollagePieces = [];
  let index = 0;

  if (source === "artists") {
    const items = response.data.items;
    while (index < items.length) {
      const artist = items[index];
      newCollagePieces.push({
        id: artist.id, // Artist ID
        imageURLOptions: [
          artist.images[0].url,
          artist.images[0].url,
          artist.images[0].url,
        ], //Artist image
        textOptions: [artist.name, artist.name, artist.name], // Artist name
        qrCodeURLOptions: [
          artist.external_urls.spotify,
          artist.external_urls.spotify,
          artist.external_urls.spotify,
        ], //Top Artist playlist or Artist page URL
        colorConfig: 0,
      });
      index = index + 1;
    }
    completionHandler(newCollagePieces);
  }

  if (source === "tracks") {
    const items = response.data.items;
    while (index < items.length) {
      const track = items[index];
      newCollagePieces.push({
        id: track.id, // Spotify track ID
        imageURLOptions: [
          track.album.images[0].url,
          //track.artists[0].images[0].url,
          track.album.images[0].url,
          track.album.images[0].url,
          //track.artists[0].images[0].url,
          //track.artists[0].images[0].url,
        ], //Album image
        textOptions: [track.name, track.artists[0].name, track.album.name], //Track name
        qrCodeURLOptions: [
          track.external_urls.spotify,
          track.artists[0].external_urls.spotify,
          track.album.external_urls.spotify,
        ], //Track URL
        colorConfig: 0,
      });
      index = index + 1;
    }
    completionHandler(newCollagePieces);
  }

  if (source === "playlist") {
    //TO-DO: Check somewhere to make sure the playlist exists first

    //var newCollagePieces = [];
    //To-Do parse response into "newCollagePieces"
    const items = response.data.tracks.items;

    while (index < items.length) {
      const track = items[index].track;
      if (track) {
        newCollagePieces.push({
          id: track.id, // Spotify track ID
          imageURLOptions: [
            track.album.images[0].url,
            //track.artists[0].images[0].url,
            track.album.images[0].url,
            track.album.images[0].url,
            //track.artists[0].images[0].url,
            //track.artists[0].images[0].url,
          ], //Album image
          textOptions: [track.name, track.artists[0].name, track.album.name], //Track name
          qrCodeURLOptions: [
            track.external_urls.spotify,
            track.artists[0].external_urls.spotify,
            track.album.external_urls.spotify,
          ], //Track URL
          colorConfig: 0,
        });
      }

      index = index + 1;
    }
    completionHandler(newCollagePieces, response.data.name);

    //Old logic when playlist came from
    /*const headers = {
      Authorization: "Bearer " + authToken,
      "Content-Type": "application/json",
    };
    return axios
      .get(response.data.items[0].tracks.href, {
        headers,
      })
      .then((response) => {
        const items = response.data.items;
        let newCollagePieces = [];
        let index = 0;
        while (index < items.length && index < 30) {
          const track = items[index].track;
          newCollagePieces.push({
            id: track.id, //Spotify identifier of the track
            imageURLOptions: [
              track.album.images[0].url,
              track.album.images[0].url,
              //track.artists[0].images[0].url,
              //need another API call to get the artist image
              track.album.images[0].url,
            ], //Album image + Album image + Artist image
            textOptions: [track.name, track.artists[0].name, track.album.name], //Track name  + Album Name + Artist Name
            qrCodeURLOptions: [
              track.external_urls.spotify,
              track.artists[0].external_urls.spotify,
              track.album.external_urls.spotify,
            ], //Track URL + Album URL + Artist URL (Either This is or Artist page)
          });
          index = index + 1;
        }
        completionHandler(newCollagePieces);
      });*/
  }
};

export { getNewCollageData };
