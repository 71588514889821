import React, { useState, useEffect, createRef } from "react";

import { useQRCode } from "next-qrcode";

const QRCode = (props) => {
  const { URL, backgroundColor, foregroundColor, scale } = props;

  const { Image } = useQRCode();

  var qrCodeScale = "qrCode";
  if (scale === "large") {
    qrCodeScale = "largeQrCode";
  }

  return (
    //container with border color = summary color of image

    <div
      className={qrCodeScale}
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      <Image
        className="TEST"
        style={{ maxWidth: "10px" }}
        text={URL}
        options={{
          type: "image/jpeg",
          quality: 0.3,
          level: "M",
          margin: 0,
          scale: 10,
          width: 600,
          color: {
            dark: foregroundColor,
            light: backgroundColor,
          },
        }}
      />
    </div>
  );
};

export default QRCode;
