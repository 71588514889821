import { PlaylistInput } from "../collageGenerator/PlaylistInput";
import whiteCloseButton from "../icons/whiteCloseButton.png";
import whiteHelpIcon from "../icons/whiteHelpIcon.png";
import landingPageBackground from "../icons/TempoTilesLandingPage3.png";

import "../App.css";
import "./landingPage.css";
import SiteHeader from "./SiteHeader";
import samplePoster1 from "../SamplePoster1.png";
import whiteFFIcon from "../icons/whiteFFIcon.png";

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  Navigate,
} from "react-router-dom";

import { addClick } from "../DatabaseOperations";

const LandingPage = (props) => {
  const { helpVisible, setHelpVisible, setCustomPlaylistID, closeMenu } = props;

  return (
    <div>
      <SiteHeader />
      <div
        style={{
          position: "absolute",
          display: "flex",
          top: "min(-15vw, -13vh)",
          overflowX: "hidden",

          //minwidth: "140vw",
          width: "100%",
          minWidth: "100vw",
          minHeight: "100vh",
          alignItems: "flex-start",
          //minHeight: "100vh",
          //height: "calc(100vh + 40vh + 10vw)",
          justifyContent: "center",

          //alignItems
        }}
      >
        <div
          style={
            {
              //position: "absolute", marginTop: "-10vh"
            }
          }
        >
          <img
            style={{
              //"calc(10vh - 10vw)",

              //display: "grid",
              justifyContent: "center",
              placeItems: "center",
              //height: "140vh",
              objectFit: "cover",
              width: "max(120vh, 120vw)",
              marginLeft: "max(15vw, 15vh)",
              opacity: "30%",
            }}
            src={landingPageBackground}
          ></img>
          <Link to="/builder">
            <img
              id="samplePoster"
              style={{
                position: "absolute",
                //paddingLeft: "-10000px",
                left: "max(65vw)",
                top: "max(32vh, 32vw)",
                height: "max(24vh, 24vw)",
                objectFit: "contain",
              }}
              src={samplePoster1}
              onClick={() => {
                console.log("clicked poster");
              }}
            ></img>
          </Link>

          <div
            className="fade-in-slight-delay"
            style={{
              position: "absolute",
              //paddingLeft: "-10000px",
              left: "0%",
              top: "max(23vh, 23vw)",
              height: "max(24vh, 30vw)",
              width: "55%",
              objectFit: "contain",

              color: "white",
              textAlign: "left",
              display: "block",
            }}
          >
            <h1
              style={{
                fontSize: "max(4vw, 6vh)",
                textShadow: "0.6vw 0.6vw #000",
                paddingLeft: "5vw",
              }}
            >
              Your playlist, on a poster
            </h1>
            <p
              style={{
                textShadow: "4px 4px #000",
                width: "95%",
                paddingLeft: "5vw",
                paddingBottom: "8vh",
                fontSize: "max(2vw, 2vh)",
                color: "white",
              }}
            >
              Create and print interactive wall art from any Spotify playlist
            </p>
            <Link to="/builder">
              <button
                //className="CTAButton"
                style={{
                  //width: "30vw",
                  //height: "5vh",
                  //backgroundColor: "#111", //"#27e0f2",
                  marginBottom: "30vh",
                  marginLeft: "5vw",
                  color: "#27e0f2",
                  textShadow: "2px 2px #000",
                  fontSize: "3vh",
                  fontWeight: "bold",
                  borderRadius: "30vh",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: "#27e0f2",
                  padding: "1.6vh 5vh 1.6vh 5vh",
                }}
                onClick={() => {
                  console.log("clicked button");
                  addClick("Let's go Button");
                }}
              >
                Let's go
              </button>
            </Link>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100vw",
                //height: "30vh",
                backgroundColor: "#111",
                color: "white",
                alignItems: "center",
                paddingBottom: "10vh",
              }}
            >
              <h2
                style={{
                  fontSize: "3vh",
                  color: "#c0c0c0",
                  paddingTop: "30px",
                }}
              >
                features
              </h2>
              {/*<img
                src={whiteFFIcon}
                style={{
                  transform: "rotate(90deg)",
                  width: "10px",
                  height: "20px",

                  marginTop: "-16px",
                  objectFit: "fit",
                }}
              ></img>*/}
              <list
                style={{
                  width: "80%",
                  textAlign: "center",
                  paddingBottom: "10px",
                }}
              >
                <li
                  style={{
                    paddingBottom: "10px",
                  }}
                >
                  pull in your playlist easily using a share link
                </li>
                <li
                  style={{
                    paddingBottom: "10px",
                  }}
                >
                  edit colors and ordering to craft the perfect poster
                </li>

                <li
                  style={{
                    paddingBottom: "10px",
                  }}
                >
                  QR codes to play music straight off your wall{" "}
                </li>
                <li
                  style={
                    {
                      //paddingBottom: "10px",
                    }
                  }
                >
                  high quality printing
                </li>
              </list>
            </div>
            <div
              style={{
                width: "100vw",
                height: "30vh",
                backgroundColor: "#000",
                color: "white",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "4vh",
              }}
            >
              Copyright 2023 East River Systems LLC.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;

{
  /*<div className="landingPage">
  <h1 style={{ color: "white" }} className="fade-in-no-delay">
          TempoTiles
        </h1>
  <div style={{ display: "flex" }}>
    <h2
      style={
        {
          //width: "min(400px, 80%)", //paddingRight: "20px"
        }
      }
      className="fade-in-slight-delay"
    >
      Create and print interactive wall art from any public Spotify playlist
    </h2>
    <button
          onClick={() => {
            setHelpVisible(!helpVisible);
          }}
          style={{
            //width: "60%"
            //position: "absolute",
            //top: "15px",
            //right: "15px",
            background: "none",
            border: "none",
            //color: "white",
          }}
        >
          <img
            src={helpVisible ? whiteCloseButton : whiteHelpIcon}
            style={{ height: "15px" }}
          ></img>
        </button>
  </div>

  <div
    style={{ width: "min(400px, 80%)", marginBottom: "50px" }}
    className="fade-in-slight-delay"
  >
    <p style={{ color: "white" }}>
      <a
        style={{ color: "#27e0f2", textOverflow: "initial" }}
        href="https://open.spotify.com"
        target="_blank"
      >
        Open Spotify in a new tab or in the app {">"}
      </a>
    </p>
  </div>
  <PlaylistInput setPlaylistID={setCustomPlaylistID} closeMenu={closeMenu} />

  <Link to="/builder/5ji5gjsdhivhLYF5WOee0R">
    <button
      className="fade-in-long-delay"
      style={{
        marginTop: "30px",
        color: "white",
        backgroundColor: "transparent",
        padding: "0px",
        border: "none",
      }}
      //onClick={() => closeMenu()}
    >
      see a sample {">"}
    </button>
  </Link>
</div>;*/
}
