import React from "react";

import logo from "../logo.png";

const SiteHeader = (props) => {
  const {
    /*backgroundColor*/
  } = props;
  var backgroundColor = "#111111";

  return (
    <header
      style={{
        position: "fixed",
        x: "0",
        y: "0",
        minWidth: "100%",
        height: "max(3.5vw, 6vh)",
        background: backgroundColor,
        zIndex: "100",
        display: "flex",
        alignItems: "leading",
        paddingBottom: "max(2vh, 1.3vw)",
        paddingTop: "2.5vh",
      }}
    >
      <h1
        style={{
          position: "relative",
          x: "0",
          //y: "0",
          //minWidth: "100%",
          //height: "10vh",
          //background: "#444444",
          //zIndex: "100",
          paddingLeft: "5vw",
          paddingRight: "1vw",
          color: "#27e0f2",
          fontSize: "max(3vh, 2vw)",
        }}
      >
        TempoTiles
      </h1>
      <img
        src={logo}
        style={{
          objectFit: "contain",
          width: "max(4vh, 3vw)",
        }}
      ></img>
    </header>
  );
};

export default SiteHeader;
