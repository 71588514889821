import React, { useRef, useState, useEffect } from "react";
import ReactDOMServer from "react-dom/server";

import CollagePiece from "./CollagePiece";
import QRCode from "./QRCode";
import whiteEditIcon from "../icons/whiteEditIcon.png";
//import "./src/app.css";

const colorSchemes = [
  { backgroundColor: "#000000", tintColor: "#ffffff" },
  { backgroundColor: "#f3ebe1", tintColor: "#000000" },
  { backgroundColor: "#ffffff", tintColor: "#000000" },
  //{ backgroundColor: "#f00000", tintColor: "#000000" },
];

const Collage = (props) => {
  const {
    pieces,
    //backgroundColor,
    //setBackgroundColor,
    imageSize,
    toShowIndex,
    setCollageRef,
    showQR,
    playlistName,
    playlistURL,
    isEditing,
    swapOutPiece,
    selectedPiece,
    setSelectedPiece,
    swapPieces,
    changePieceColor,
    showInstructions,
    setShowInstructions,
  } = props;

  const [colorScheme, setColorScheme] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState("#000000");
  const [tintColor, setTintColor] = useState("#ffffff");
  //const [showingHelp, setShowingHelp] = useState(true);
  //const

  useEffect(() => {
    setBackgroundColor(colorSchemes[colorScheme].backgroundColor);
    setTintColor(colorSchemes[colorScheme].tintColor);
  }, [colorScheme]);

  const collageRef = useRef();
  useEffect(() => {
    setCollageRef(collageRef);
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <div
        className="collageContainer"
        ref={collageRef}
        style={{
          backgroundColor: backgroundColor,
          "--image-size": imageSize,
        }}
      >
        <div className="collage">
          {pieces.map((piece, index) => {
            //console.log(piece.id);
            let isSelected = false;
            if (index === selectedPiece) {
              isSelected = true;
            }
            let isSwapping = false;
            if (selectedPiece > -1) {
              isSwapping = true;
            }

            if (index < 20) {
              return (
                <CollagePiece
                  //key={piece.id}
                  id={piece.id}
                  imageURL={piece.imageURLOptions[toShowIndex]}
                  text={piece.textOptions[toShowIndex]}
                  qrCodeURL={piece.qrCodeURLOptions[toShowIndex]}
                  showQR={showQR}
                  isEditing={isEditing}
                  swapOutPiece={() => {
                    swapOutPiece(index);
                  }}
                  isSelected={isSelected}
                  setSelectedPiece={() => {
                    setSelectedPiece(index);
                  }}
                  isSwapping={isSwapping}
                  swap={() => swapPieces(index)}
                  colorConfig={piece.colorConfig}
                  changeColors={() => {
                    changePieceColor(index);
                  }}
                ></CollagePiece>
              );
            }
          })}
        </div>
        <div
          className="posterFooter"
          style={{
            color: tintColor,
          }}
        >
          <div className="posterFooterTextContainer">
            <div className="posterFooterText">{playlistName}</div>
            {/*isEditing ? (
              <img
                style={{
                  height: "80%",
                  marginLeft: "8px",
                  padding: "5px",
                  borderRadius: "calc(var(--image-size) / 1)",
                  borderStyle: "solid",
                  borderColor: tintColor,
                  borderWidth: "calc(var(--image-size) / 100)",
                }}
                src={whiteEditIcon}
                onClick={() => {
                  alert("editing PlaylistName");
                }}
              ></img>
              ) : null*/}
          </div>

          {showQR ? (
            <QRCode
              URL={playlistURL} //URL={"youtu.be/dQw4w9WgXcQ"}
              backgroundColor={backgroundColor}
              foregroundColor={tintColor}
              scale={"large"}
            />
          ) : null}
        </div>
        {isEditing ? (
          <div
            style={{
              width: "calc(var(--image-size) / 2)",
              height: "calc(var(--image-size) / 2)",
              marginLeft: "calc(-1 * var(--image-size) / 5)",
              marginTop: "calc(-1 * var(--image-size) / 5)",
              position: "absolute",
              left: "calc(var(--image-size) / 40)",
              top: "calc(var(--image-size) / 40)",
              backgroundColor: tintColor,
              padding: "calc(var(--image-size) / 15)",
              borderRadius: "calc(var(--image-size) / 1)",
              borderStyle: "solid",
              borderColor: backgroundColor,
              borderWidth: "calc(var(--image-size) / 100)",
            }}
            onClick={() => {
              //changeColorConfig();
              let nextScheme = colorScheme + 1;
              if (nextScheme === colorSchemes.length) {
                setColorScheme(0);
              } else setColorScheme(nextScheme);
            }}
          ></div>
        ) : null}
        {isEditing && showInstructions ? (
          <div
            style={{
              position: "fixed",
              top: "0",
              position: "fixed",
              backgroundColor: "#111111",
              //top: "0px",
              left: "0px",
              minHeight: "150vh",
              minWidth: "150vw",
              opacity: "80%",
              zIndex: "0",
            }}
            onClick={() => {
              setShowInstructions(false);
            }}
          />
        ) : null}
        {isEditing && showInstructions ? (
          <div
            className="successPopUp"
            style={{ opacity: "100%", color: "white" }}
          >
            <h1>Editor Mode</h1>
            {/*<CollagePiece
              id={pieces[0].id}
              imageURL={pieces[0].imageURLOptions[toShowIndex]}
              text={pieces[0].textOptions[toShowIndex]}
              qrCodeURL={pieces[0].qrCodeURLOptions[toShowIndex]}
              showQR={true}
              isEditing={true}
              swapOutPiece={() => {
                //swapOutPiece(0);
              }}
              isSelected={false}
              setSelectedPiece={() => {
                //setSelectedPiece(0);
              }}
              isSwapping={false}
              swap={() =>
                //swapPieces(index)
                {}
              }
              colorConfig={pieces[0].colorConfig}
              changeColors={() => {
                //changePieceColor(index);
              }}
              instructions={true}
            />*/}
            <div style={{ width: "100%", textAlign: "left", fontSize: "16px" }}>
              <p>- Click on the top left to change colors</p>
              <p>
                - Click on the top right to replace with a new song from your
                playlist{" "}
              </p>
              <p>- Click on one tile then another to swap positions</p>
            </div>

            <button
              style={{
                color: "white",
                backgroundColor: "transparent",
                border: "none",
                padding: "20px",
                fontSize: "20px",
              }}
              onClick={() => {
                setShowInstructions(false);
              }}
            >
              close
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Collage;
