import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
} from "@stripe/react-stripe-js";

import "./order.css";

const CheckoutForm = (props) => {
  const { orderID, updateOrder, clientSecret, paymentID } = props;
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (!stripe) {
      return;
    }
    updateOrder({
      status: "order started",
      paymentID: paymentID,
    });
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      /*console.log(paymentIntent);
      console.log(clientSecret);
      console.log(orderID);*/
    });
  }, [stripe]);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    console.log(email);
    updateOrder({
      email: email,
    });

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    /*var url;
    if ((process.env.NODE_ENV = "development")) {
      url = "http://localhost:3000";
    } else {
      url = "https://tempotiles.com";
    }*/

    var returnURL = "http://localhost:3000/success";
    if (process.env.NODE_ENV === "production") {
      returnURL = "https://tempotiles.com/success";
    }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: returnURL,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      //console.log(error);
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      //alert("successful order");
    }
  };

  const inputChangeHandler = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
  };

  return (
    <form onSubmit={handleSubmit} style={{ color: "white", textAlign: "left" }}>
      <h3 style={{ color: "white", textAlign: "left" }}>Shipping Address</h3>
      <AddressElement
        options={{
          mode: "shipping",
          defaultValues: {
            name: "Jane Doe",
            address: {
              line1: "",
              line2: "",
              city: "",
              state: "XX",
              postal_code: "",
              country: "US",
            },
          },
        }}
      />
      <h3 style={{ color: "white", textAlign: "left" }}>Payment</h3>
      <PaymentElement />
      <label style={{ marginTop: "20px", display: "inline-block" }}>
        Email for receipt (optional)
      </label>
      <input
        style={{
          width: "calc(100% - 12px)",
          height: "26px",
          marginBottom: "10px",
          marginTop: "10px",
          backgroundColor: "black",
          color: "white",
          borderRadius: "8px",
          fontSize: "16px", //so apple doesnt auto zoom in
          borderColor: "white",
          borderStyle: "solid",
          padding: "5px",
        }}
        type="text"
        //inputMode="none"
        //name="playlist"
        //value="5UnGFvKZ3NbEedhn3nCfAJ"
        onChange={(e) => inputChangeHandler(e)}
        //value={playlistURL}
        //onPaste={(e) => pasteHandler(e)}
      />
      <div
        style={{
          color: "white",
          fontSize: "12px",
          textAlign: "center",
          marginTop: "15px",
        }}
      >
        by clicking pay, you accept the{" "}
        <a href="terms" target="_blank">
          terms and conditions
        </a>
      </div>
      <button
        style={{
          marginTop: "8px",
          padding: "12px",
          paddingLeft: "35px",
          paddingRight: "35px",
          height: "auto",
          width: "100%",
          backgroundColor: "green",
          color: "white",
          zIndex: "5",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "white",
          borderRadius: "25px",
        }}
        disabled={!stripe}
      >
        Pay $30.00
      </button>
      {/* Show error message to your customers */}
      {errorMessage ? (
        <div
          style={{ color: "white", paddingTop: "10px", textAlign: "center" }}
        >
          {errorMessage}
        </div>
      ) : null}
    </form>
  );
};

export default CheckoutForm;
