import React, { useState, useEffect, createRef } from "react";

import ColorThief from "colorthief";
import QRCode from "./QRCode";

import whiteCloseButton from "../icons/whiteCloseButton.png";
import whiteFFIcon from "../icons/whiteFFIcon.png";
import moveIcon from "../icons/moveIcon.png";
import whiteShuffleIcon from "../icons/whiteShuffleIcon.png";
import replaceIcon from "../icons/replaceIcon.png";

const rgbToHex = (rgb) =>
  "#" +
  rgb
    .map((x) => {
      const hex = x.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    })
    .join("");

const convertToGrayscale = (color) => {
  //console.log(color[0] / 255 + color[1] / 255 + color[2] / 255);
  return (color[0] / 255 + color[1] / 255 + color[2] / 255) * 255;
};

const darkenColor = (rgbColor) => {
  //console.log(rgbColor.map((x) => x));
  return rgbColor.map((x) => (x - (x % 4)) / 4);
};

const lightenColor = (rgbColor) => {
  //console.log(rgbColor.map((x) => x));
  return rgbColor.map((x) => (x - (x % 2)) / 2);
};

const chooseTintColor = (backgroundColor) => {
  if (convertToGrayscale(backgroundColor) > 200) {
    return [0, 0, 0];
    //return rgbToHex(darkenColor(backgroundColor));
  }
  return [255, 255, 255];
};

const CollagePiece = (props) => {
  const {
    id,
    imageURL,
    text,
    qrCodeURL,
    showQR,
    isEditing,
    swapOutPiece,
    isSelected,
    setSelectedPiece,
    isSwapping,
    swap,
    colorConfig,
    changeColors,
    instructions,
  } = props;

  const [backgroundColor, setBackgroundColor] = useState("#000000");
  const [tintColor, setTintColor] = useState("#FFFFFF");
  const [colors, setColors] = useState(["#000000", "#FFFFFF", "#000000"]);
  //const [colorsToUse, setColorsToUse] = useState(colorConfig);

  const colorConfigs = [
    [0, 1],
    [1, 0],
    [1, 2],
    [2, 1],
    [2, 0],
    [0, 2],
  ];

  const imgRef = createRef();

  useEffect(() => {
    setBackgroundColor(colors[colorConfigs[colorConfig][0]]);
    setTintColor(colors[colorConfigs[colorConfig][1]]);
  }, [colors]); //, colorsToUse]);

  const invertColors = () => {
    let newTint = backgroundColor;
    setBackgroundColor(tintColor);
    setTintColor(newTint);
  };

  const changeColorConfig = () => {
    if (!instructions) {
      if (colorConfig >= 5) {
        setBackgroundColor(colors[colorConfigs[0][0]]);
        setTintColor(colors[colorConfigs[0][1]]);
      } else {
        setBackgroundColor(colors[colorConfigs[colorConfig + 1][0]]);
        setTintColor(colors[colorConfigs[colorConfig + 1][1]]);
      }
      changeColors();
    }
  };

  //console.log(tintColor);

  //console.log(collageRef.current);

  return (
    //container with border color = summary color of image
    <div
      //key={key}
      className="collagePiece"
      style={{
        backgroundColor: backgroundColor,
        border: "0px",
        borderStyle: "solid",
        borderColor: tintColor,
      }}
    >
      <div
        className="collagePieceImageContainer"
        style={{
          border: "calc(var(--image-size) / 80)",
          borderColor: tintColor,
          borderStyle: "solid",
        }}
        onClick={() => {
          /*if (isSwapping) {
            swap();
          } else {
            setSelectedPiece();
          }*/
          if (isEditing) {
            if (isSwapping) {
              swap();
            } else {
              setSelectedPiece();
            }

            //invertColors();
          } else {
            changeColorConfig();
          }
        }}
      >
        <img
          className="collagePieceImage"
          crossOrigin={"anonymous"}
          ref={imgRef}
          alt=""
          src={imageURL}
          style={{
            textIndent: "-10000px",
          }}
          onLoad={() => {
            const img = imgRef.current;

            if (img.naturalWidth < img.naturalHeight) {
              imgRef.current.style.setProperty("width", "100%");
              imgRef.current.style.setProperty("height", "auto");
            } else {
              imgRef.current.style.setProperty("height", "100%");
              imgRef.current.style.setProperty("width", "auto");
            }

            // get dominant color
            const colorThief = new ColorThief();

            //const color = colorThief.getColor(img, 25);
            const palette = colorThief.getPalette(img, 3, 25);

            //setBackgroundColor(rgbToHex(palette[0]));

            //Working on Donda Logic
            let contrastScore = 0;
            for (let i = 0; i < 3; i++) {
              contrastScore += Math.abs(palette[0][i] - palette[1][i]);
            }
            if (contrastScore < 10) {
              console.log("same-same");
              //setTintColor("#FFFFFF");
              setColors([
                rgbToHex(palette[0]),
                "#FFFFFF",
                rgbToHex(palette[2]),
              ]);
            } else {
              //setTintColor(rgbToHex(palette[1]));
              setColors([
                rgbToHex(palette[0]),
                rgbToHex(palette[1]),
                rgbToHex(palette[2]),
              ]);
            }
          }}
        />
      </div>
      <div
        className="collagePieceFooter"
        style={{
          color: tintColor,
        }}
        onClick={() => {
          /*if (isSwapping) {
            swap();
          } else {
            setSelectedPiece();
          }*/
          if (isEditing) {
            if (isSwapping) {
              swap();
            } else {
              setSelectedPiece();
            }

            //invertColors();
          } else {
            changeColorConfig();
          }
        }}
      >
        <div className="collagePieceFooterTextContainer">
          <div className="collagePieceFooterText">{text}</div>
        </div>
        {showQR ? (
          <QRCode
            URL={qrCodeURL}
            backgroundColor={backgroundColor}
            foregroundColor={tintColor}
            scale="normal"
          />
        ) : null}
      </div>
      {isSelected ? (
        <div>
          <div
            style={{
              backgroundColor: "white",
              opacity: "40%",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              borderRadius: "inherit",
            }}
            onClick={() => {
              swap();
            }}
          ></div>
          <img
            style={{
              width: "calc(var(--image-size) / 2)",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -65%)",
              backgroundColor: "#333333",
              padding: "calc(var(--image-size) / 8)",
              borderRadius: "calc(var(--image-size) / 1)",
              //borderStyle: "solid",
              //borderColor: "white",
              //borderWidth: "calc(var(--image-size) / 80)",
              opacity: "50%",
            }}
            src={whiteShuffleIcon}
            onClick={() => {
              swap();
            }}
          ></img>
        </div>
      ) : null}
      {/*isSwapping && !isSelected ? (
        <div
          style={{
            backgroundColor: "black",
            opacity: "40%",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "inherit",
          }}
          onClick={() => {
            swap();
          }}
        ></div>
        ) : null*/}
      {isEditing && !(isSelected || isSwapping) ? (
        <div>
          <img
            style={{
              width: "calc(var(--image-size) / 3)",
              position: "absolute",
              marginRight: "calc(-1 * var(--image-size) / 10)",
              marginTop: "calc(-1 * var(--image-size) / 10)",
              right: "calc(var(--image-size) / 40)",
              top: "calc(var(--image-size) / 40)",
              backgroundColor: "#333333",
              padding: "calc(var(--image-size) / 15)",
              borderRadius: "calc(var(--image-size) / 1)",
              borderStyle: "solid",
              borderColor: "white",
              borderWidth: "calc(var(--image-size) / 160)",
            }}
            onClick={() => {
              swapOutPiece();
            }}
            src={replaceIcon}
          ></img>
          <div
            style={{
              width: "calc(var(--image-size) / 3)",
              height: "calc(var(--image-size) / 3)",
              marginLeft: "calc(-1 * var(--image-size) / 10)",
              marginTop: "calc(-1 * var(--image-size) / 10)",
              position: "absolute",
              left: "calc(var(--image-size) / 40)",
              top: "calc(var(--image-size) / 40)",
              backgroundColor: tintColor,
              padding: "calc(var(--image-size) / 15)",
              borderRadius: "calc(var(--image-size) / 1)",
              borderStyle: "solid",
              borderColor: backgroundColor,
              borderWidth: "calc(var(--image-size) / 160)",
            }}
            onClick={() => {
              changeColorConfig();
            }}
          ></div>
        </div>
      ) : null}
    </div>
  );
};

export default CollagePiece;
