import { useState, useEffect } from "react";

import { placeCloudprinterOrder } from "../Cloudprinter";

import { Elements, CardElement } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";

/*var stripePublic = process.env.REACT_APP_STRIPE_TEST_PUBLIC;
if (process.env.NODE_ENV === "production") {
  stripePublic = process.env.REACT_APP_STRIPE_PUBLIC;
  console.log(stripePublic);
}*/
const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);
//const stripe = loadStripe(process.env.REACT_APP_STRIPE_TEST_PUBLIC);

const testAddress = {
  type: "delivery",
  //company: "Example company",
  firstname: "David",
  lastname: "Lane",
  street1: "655 Navy Street",
  zip: "90405",
  city: "Los Angeles",
  state: "CA",
  country: "US",
  email: "davidlanedc@yahoo.com",
  phone: "2024896915",
};

export const PaymentInterface = (props) => {
  const {
    loading,
    setLoading,
    md5sum,
    downloadURL,
    orderID,
    updateOrder,
    downloadImage,
    moveToResultState,
    clientSecret,
  } = props;

  const appearance = {
    theme: "night", //, labels: "floating"
  };

  return (
    <div style={{ minHeight: "60vh", marginBottom: "50px" }}>
      {/*<div
        style={{
          position: "fixed",
          top: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "black",
        }}
      ></div>*/}
      <div
        style={{
          position: "absolute",
          top: "0px",
          left: "50%",
          marginBottom: "50px",
          marginTop: "50px",
          transform: "translateX(-50%)",
          //transform: "translateY(50%)",
          width: "min(600px, 80%)",
          height: "auto",
          backgroundColor: "#111111",
          padding: "20px",
          paddingTop: "10px",
          borderRadius: "15px",
          zIndex: "1",
        }}
      >
        {/*{clientSecret === "" ? null : (*/}
        <Elements stripe={stripe} options={{ clientSecret, appearance }}>
          <CheckoutForm
            orderID={orderID}
            updateOrder={updateOrder}
            clientSecret={clientSecret}
            paymentID={clientSecret.split("_secret_")[0]}
          />
        </Elements>
        {/*
        )}
      */}
      </div>
    </div>
  );

  /*return (
    <div>
      <div
        style={{
          position: "fixed",
          //padding: "100px",
          top: "0",
          left: "0",
          height: "100%",
          width: "100%",
          backgroundColor: "green",
        }}
      >
        This will be a code entry for now, run payment, then show the order ID
        returned by the cloudprintAPI
        <form onSubmit={(e) => e.preventDefault()}>
          <label>
            Address:
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </label>
        </form>
        <button
          onClick={() => {
            downloadImage();
          }}
        >
          download a preview
        </button>
      </div>

      <button
        onClick={() => {
          console.log("checking result");
          if (verifyInputs()) {
            setLoading(true);
            placeCloudprinterOrder(md5sum, downloadURL, orderID, testAddress)
              .then((response) => {
                console.log(response);
                updateOrder({
                  status: "cloudprinter order placed",
                  address: testAddress,
                });
                setLoading(false);
                moveToResultState();
              })
              .catch((error) => {
                alert(error);
                setLoading(false);
                //moveToResultState();
              });
          }
        }}
        style={{
          position: "fixed",
          padding: "10px",
          margin: "10px",
          bottom: "0",
          right: "0",
          height: "auto",
          width: "auto",
          backgroundColor: "white",
        }}
        disabled={loading}
      >
        Finish
      </button>
    </div>
  );*/
};
