import { upload } from "@testing-library/user-event/dist/upload";
import React, { useEffect, useState } from "react";

import "./order.css";

import { getDownloadURL } from "firebase/storage";

import { v4 } from "uuid";
import { Buffer } from "buffer";
import axios from "axios";

import html2canvas from "html2canvas";
import * as htmlToImage from "html-to-image";

//import CryptoJS from "crypto-js";
import { serverTimestamp } from "firebase/firestore";

import {
  addOrderDocument,
  updateOrderDocument,
  deleteOrder,
  uploadToStorage,
} from "../DatabaseOperations";

import { downloadImage, elementToImage } from "../Utility";

import { OrderDetailsInterface } from "./OrderDetails";
import { PaymentInterface } from "./Payment";

const Order = (props) => {
  //Ordering screen component to manage the state of an order

  const { collageRef, imageSize, collageInfo, stopOrdering } = props;

  const [collage, setCollage] = useState(collageInfo);

  const [orderState, setOrderState] = useState("preview");
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(
    "We're creating a large, high-quality file with your customizations. This could take up to two minutes."
  );

  const [orderRef, setOrderRef] = useState(null);
  const [orderID, setOrderID] = useState("");

  const [md5sum, setMd5sum] = useState("");
  const [downloadURL, setDownloadURL] = useState("");

  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    if (loading && orderState === "preview") {
      startOrder();
    }
  }, [loading]);

  useEffect(() => {}, [loadingMessage]);

  const startOrder = async (collage) => {
    //FOR TESTING
    //setOrderState("orderDetails");

    //Loading......

    //create the image blob of the collage
    collageRef.current.style.setProperty("--image-size", "510px");
    if (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    ) {
      collageRef.current.style.setProperty("--image-size", "170px");
    } /* else if (window.devicePixelRatio) {
      let pixels = 640 / window.devicePixelRatio;
      collageRef.current.style.setProperty("--image-size", pixels + "px");
    } else {
      collageRef.current.style.setProperty("--image-size", "320px");
    }*/

    //Max canvas height on iOS in only 4700...

    const storageID = v4();

    html2canvas(collageRef.current, {
      allowTaint: true,
      useCORS: true,
      letterRendering: true,
    }).then((canvas) => {
      canvas.toBlob((blob) => {
        collageRef.current.style.setProperty("--image-size", imageSize);
        uploadToStorage(blob, storageID)
          .then((snapshot) => {
            console.log("PNG successfully added to storage!");

            getDownloadURL(snapshot.ref)
              .then((downloadURL) => {
                console.log(`image uploaded at ${downloadURL}`);

                setDownloadURL(downloadURL);

                //if successful add document to database, include fields for storageID, collage Info, then get its ID
                //set ID variable for the order
                const updated_at_timestamp = serverTimestamp();
                addOrderDocument({
                  storageID: storageID,
                  status: "poster uploaded",
                  /*log: [
                    {
                      status: "poster uploaded",
                      timestamp: updated_at_timestamp,
                    },
                  ],*/
                  storageURL: downloadURL,
                  started_at: updated_at_timestamp,
                  //md5sum: bufString,
                })
                  .then((docRef) => {
                    setOrderID(docRef.id);
                    setOrderRef(docRef);

                    console.log("Document successfully written!");

                    //block ui until we get back an ID from the DB

                    //useEffect(() => {
                    var backendURL = "";

                    //if (process.env.NODE_ENV === "production") {
                    backendURL =
                      "https://us-central1-tempotiles.cloudfunctions.net/app/secret";
                    //} else {
                    //backendURL = "http://localhost:5001/tempotiles/us-central1/app/testsecret";
                    //}
                    axios.get(backendURL).then((response) => {
                      setClientSecret(response.data.message);
                      console.log("Payment intent: " + response.data.message);

                      setOrderState("payment");
                      //Stop Loading!
                      setLoading(false);
                    });
                    //}, []);
                  })
                  .catch((error) => {
                    console.error("Error writing document: ", error);

                    setLoading(false);
                  });
              })
              .catch((error) => {
                console.error(
                  "Error getting downloadURL from storage: ",
                  error
                );

                setLoading(false);
              });
          })
          .catch((error) => {
            console.error("Error uploading pdf to storage: ", error);
            setLoading(false);
          });
      });
    });

    //const blob = await elementToImage(collageRef.current);

    //Will do PDF conversion on the server

    //});

    //const blob = pdf.output("blob");

    /* uploadToStorage(blob, storageID)
      .then((snapshot) => {
        console.log("PDF successfully added to storage!");

        //get and convert the md5sum
        const buffer = Buffer.from(snapshot.metadata.md5Hash, "base64");
        const bufString = buffer.toString("hex");
        console.log("md5", bufString);
        setMd5sum(bufString);

        getDownloadURL(snapshot.ref)
          .then((downloadURL) => {
            console.log(`image uploaded at ${downloadURL}`);

            setDownloadURL(downloadURL);

            //if successful add document to database, include fields for storageID, collage Info, then get its ID
            //set ID variable for the order
            addOrderDocument({
              storageID: storageID,
              status: "poster uploaded",
              storageURL: downloadURL,
              md5sum: bufString,
            })
              .then((docRef) => {
                setOrderID(docRef.id);
                setOrderRef(docRef);

                console.log("Document successfully written!");

                //block ui until we get back an ID from the DB

                //useEffect(() => {
                var backendURL = "";

                if (process.env.NODE_ENV === "production") {
                  backendURL =
                    "https://us-central1-tempotiles.cloudfunctions.net/app/secret";
                } else {
                  backendURL =
                    "http://localhost:5001/tempotiles/us-central1/app/secret";
                }
                axios.get(backendURL).then((response) => {
                  setClientSecret(response.data.message);
                  setOrderState("payment");
                  //Stop Loading!
                  setLoading(false);
                });
                //}, []);
              })
              .catch((error) => {
                console.error("Error writing document: ", error);

                setLoading(false);
              });
          })
          .catch((error) => {
            console.error("Error getting downloadURL from storage: ", error);

            setLoading(false);
          });
      })
      .catch((error) => {
        console.error("Error uploading pdf to storage: ", error);
        setLoading(false);
      });
      */
  };

  const updateOrder = (data) => {
    setLoading(true);

    updateOrderDocument(orderRef, data)
      .then(() => {
        //block ui until we get back an ID from the DB

        setLoading(false);
        //setOrderState("payment");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
        setLoading(false);
      });
  };

  const endOrder = () => {
    setOrderState("ended");

    //reset the ui
    stopOrdering();
  };

  const cancelOrder = (orderID) => {
    //delete the image of the poster and
    //delete the order document
    if (orderRef !== null) {
      deleteOrder(orderRef);
    }

    endOrder();
  };

  return (
    <React.Fragment>
      {orderState !== "success" ? (
        <button
          onClick={() => {
            cancelOrder(orderID);
          }}
          style={{
            position: "fixed",
            padding: "10px",
            top: "2vh",
            left: "2vw",
            height: "auto",
            width: "auto",
            backgroundColor: "#222222",
            color: "white",
            zIndex: "5",
            border: "1px",
            borderColor: "white",
            borderRadius: "5px",
          }}
          disabled={loading}
        >
          {"< Back"}
        </button>
      ) : (
        <button
          onClick={() => {
            endOrder();
          }}
          style={{
            position: "fixed",
            padding: "10px",
            top: "0",
            left: "0",
            height: "auto",
            width: "auto",
            backgroundColor: "white",
            zIndex: "9",
          }}
          disabled={loading}
        >
          Close
        </button>
      )}

      {orderState === "preview" ? (
        <OrderPreviewInterface
          loading={loading}
          startOrder={() => {
            setLoading(true);
          }}
        />
      ) : null}
      {/*orderState === "orderDetails" ? (
        <OrderDetailsInterface
          loading={loading}
          updateOrder={(data) => {
            if (orderRef !== null) {
              updateOrder(data);
            } else {
              //Error
            }
          }}
          moveToPaymentState={() => {
            setOrderState("payment");
          }}
        />
        ) : null*/}
      {orderState === "payment" ? (
        <PaymentInterface
          loading={loading}
          setLoading={(bool) => setLoading(bool)}
          md5sum={md5sum}
          downloadURL={downloadURL}
          orderID={orderID}
          updateOrder={(data) => {
            if (orderRef !== null) {
              updateOrder(data);
            } else {
              //Error
            }
          }}
          downloadImage={() => {
            downloadImage(collageRef.current);
          }}
          moveToResultState={() => {
            setOrderState("success");
          }}
          clientSecret={clientSecret}
        />
      ) : null}
      {orderState === "success" ? (
        <SuccessInterface finishOrder={() => {}} />
      ) : null}
      {loading && orderState === "preview" ? (
        <div
          style={{
            touchAction: "none",
            position: "fixed",
            paddingTop: "20px",
            top: "0",
            left: "0",
            minHeight: "200%",
            width: "100vw",
            backgroundColor: "#111111",
            color: "white",
            zIndex: "10",
          }}
        >
          <h1
            style={{
              transform: "translateY(50%)",
              marginTop: "10%",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            {" "}
            Assembling your poster
          </h1>
          <div
            style={{
              transform: "translateY(50%)",
              paddingLeft: "30px",
              paddingRight: "30px",
            }}
          >
            {loadingMessage}
          </div>
          <div className="loadingAnimation"></div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

const OrderPreviewInterface = (props) => {
  const { loading, startOrder } = props;

  return (
    <div
      style={{
        display: "flex",

        flexDirection: "column",
        position: "fixed",
        margin: "10px",
        bottom: "0",
        right: "0",
      }}
    >
      <div
        style={{
          color: "white",
          backgroundColor: "#333333",
          marginBottom: "10px",
          paddingBottom: "20px",
          paddingTop: "20px",
          paddingRight: "20px",
          paddingLeft: "20px",
          borderRadius: "15px",
        }}
      >
        {/*<h4>Poster Size</h4>*/}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "10px",
          }}
        >
          <div /*onChange={(e) => setPosterSize(e.target.value)}*/>
            <input
              type="radio"
              id="18x24"
              name="posterSize"
              value="poster_18000x24000_in_fc"
              defaultChecked="checked"
            />
            18x24in - $30.00
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div /*onChange={(e) => setPosterSize(e.target.value)}*/>
            <input
              type="radio"
              id="24x36"
              name="posterSize"
              value="poster_24000x36000_in_fc"
              //checked="checked"
              disabled="true"
            />
            24x36in - unavailable
          </div>{" "}
        </div>
        <div
          style={{
            color: "white",
            paddingTop: "8px",
            paddingBottom: "20px",
            fontSize: "12px",
          }}
        >
          shipping is free
        </div>
        <button
          onClick={() => {
            console.log("order details screen");
            startOrder();
          }}
          style={{
            padding: "12px",
            paddingLeft: "35px",
            paddingRight: "35px",
            height: "auto",
            width: "auto",
            backgroundColor: "green",
            color: "white",
            zIndex: "5",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "white",
            borderRadius: "25px",
          }}
          disabled={loading}
        >
          Proceed to checkout
        </button>
      </div>
    </div>
  );
};

const SuccessInterface = (props) => {
  const {} = props;

  return (
    <div>
      {
        <div
          style={{
            position: "fixed",
            //padding: "100px",
            top: "0",
            left: "0",
            height: "100%",
            width: "100%",
            backgroundColor: "green",
          }}
        >
          <div
            style={{
              position: "fixed",
              //padding: "100px",
              top: "50%",
              left: "50%",
              //height: "100%",
              //width: "100%",
              backgroundColor: "green",
            }}
          >
            Success!
          </div>
        </div>
      }
    </div>
  );
};

export default Order;
