import * as htmlToImage from "html-to-image";
import html2canvas from "html2canvas";

export const downloadImage = async (element, fileName) => {
  let pdf = await elementToImage(element);
  pdf.save("testHighQuality.pdf");
};

export const elementToImage = async (element) => {
  const canvas = await html2canvas(element, {
    allowTaint: true,
    useCORS: true,
    letterRendering: true,
  });

  await canvas.toBlob((blob) => {
    return blob;
  });
};
