import { PlaylistInput } from "./PlaylistInput";
import whiteCloseButton from "../icons/whiteCloseButton.png";
import whiteHelpIcon from "../icons/whiteHelpIcon.png";

import "../App.css";

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import CollageGenerator from "./CollageGenerator";

const WelcomeScreen = (props) => {
  const { helpVisible, setHelpVisible, setCustomPlaylistID, closeMenu } = props;

  return (
    <div>
      <CollageGenerator
        //authToken={getAuth()}
        currentUserName="NEED THIS"
        //startingCollageSource={collageSource}
        //success={success}
        //areControlsVisible={areControlsVisible}
      />
      <div className="welcomeScreen fade-in-long-delay">
        <h1 style={{ color: "white" }} className="fade-in-no-delay">
          Builder
        </h1>
        <div style={{ display: "flex" }}>
          {/*<h3
            style={{
              width: "min(400px, 80%)", //paddingRight: "20px"
            }}
            className="fade-in-slight-delay"
          >
            Create and print interactive wall art from any public Spotify
            playlist
          </h3>*/}
          {/*<button
          onClick={() => {
            setHelpVisible(!helpVisible);
          }}
          style={{
            //width: "60%"
            //position: "absolute",
            //top: "15px",
            //right: "15px",
            background: "none",
            border: "none",
            //color: "white",
          }}
        >
          <img
            src={helpVisible ? whiteCloseButton : whiteHelpIcon}
            style={{ height: "15px" }}
          ></img>
        </button>*/}
        </div>

        <div
          style={{ width: "min(400px, 80%)", marginBottom: "50px" }}
          className="fade-in-slight-delay"
        >
          <p style={{ color: "white" }}>
            1. Make a <b>public</b> playlist with 20 songs that you want to have
            on your poster
          </p>
          <p style={{ color: "white" }}>
            2. Click the three dots at the top of the playlist, then click share
            and copy the link
          </p>
          <p style={{ color: "white" }}>
            3. Paste the link into the input below and we'll generate your
            poster
            <br />
            <br />
            Please note: your link must contain open.spotify.com the new
            shortened spotify.link format will not work. You can get the
            open.spotify.com version by opening the spotify.link in your
            browser.
            <br />
            <br />
            <a
              style={{ color: "#27e0f2", textOverflow: "initial" }}
              href="https://open.spotify.com"
              target="_blank"
            >
              Open Spotify in a new tab or in the app {">"}
            </a>
          </p>
        </div>
        <PlaylistInput
          setPlaylistID={setCustomPlaylistID}
          closeMenu={closeMenu}
        />

        <Link to="/builder/5ji5gjsdhivhLYF5WOee0R">
          <button
            className="fade-in-long-delay"
            style={{
              marginTop: "30px",
              color: "white",
              backgroundColor: "transparent",
              padding: "0px",
              border: "none",
            }}
            //onClick={() => closeMenu()}
          >
            see a sample {">"}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default WelcomeScreen;
